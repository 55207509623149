import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';

const StyledSlider = styled.div`
	.next,
	.prev {
		position: relative;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			width: 0;
			height: 0;
			display: block;
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
		}
		&.end::after {
			opacity: 0.5;
		}
	}
	.prev::after {
		border-right: 15px solid #203352;
		right: 10px;
	}
	.next::after {
		border-left: 15px solid #203352;
		left: 10px;
	}
`;

const Slider = ({ images }) => {
	const [slide, setSlide] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			if (document.querySelector('.slider-list').offsetHeight === 0) {
				window.dispatchEvent(new Event('resize'));
				console.log('fixed it');
			}
		}, 100);
	});

	return (
		<StyledSlider>
			<Carousel
				afterSlide={(slideIndex) => setSlide(slideIndex)}
				renderCenterLeftControls={({ previousSlide }) => (
					<span
						className={slide === 0 ? 'prev end' : 'prev'}
						onClick={previousSlide}
					/>
				)}
				renderCenterRightControls={({ nextSlide }) => (
					<span
						className={slide === images.length - 1 ? 'next end' : 'next'}
						onClick={nextSlide}
					/>
				)}
				renderBottomCenterControls={null}
			>
				{images.map((item, index) => (
					<img key={index} src={item} alt={`project slide ${index}`} />
				))}
			</Carousel>
		</StyledSlider>
	);
};

export default Slider;

import React from 'react';
import styled from 'styled-components';

const StyledMasthead = styled.div`
		background-image: url(${(p) => p.img});
		background-position: center;
		background-size: cover;
		padding: 0rem 4rem;
	display: flex;
	align-items: center;
	height: calc(7rem + ${(p) => p.margin});
	&.project {
		height: auto;
		padding: 2rem 4rem
						}
						h1 {
							padding-top: 15px;
							position: relative;
									margin-top: ${(p) => p.margin};
									text-transform: uppercase;
									&::before {
				content: "${(p) => p.subtext}";
				color: ${(p) => p.theme.color.red};
				font-size: 1rem;
				position: absolute;
				top: -25px;
		left: 2px;
	}
}
@media all and (min-width: 1600px) {
height: calc(9rem + calc(${(p) => p.margin} * 2));
	padding: 0rem 5.5rem;
		h1 {
			font-size: 3rem;
			line-height: 3.5rem;
			&::before {
				font-size: 1.5rem;
				top: -35px;
			}
		}
}
@media all and (max-width: 450px) {
	&.project{
		padding: 2rem 2rem;
	}									
			}
			@media all and (max-width: 400px) {
				h1{
					font-size: 2rem;
					line-height: 2..5rem;
				}
}
`;

const Masthead = ({ title, img, subtext, className }) => {
	//add margin &  height if subtext parameter is defined
	const margin = subtext !== undefined ? '10px' : '0px';
	return (
		<StyledMasthead
			className={className}
			img={img}
			subtext={subtext}
			margin={margin}
		>
			<h1>{title}</h1>
		</StyledMasthead>
	);
};

export default Masthead;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledRinksCTA = styled.div`
	background-image: url(/img/FLR_About_Blue_Ice.jpg);
	background-size: cover;
	padding: 6rem 0;
	position: relative;
	&.rinks {
		&::before {
			right: 0;
			left: auto;
		}
		text-align: center;
		.left {
			width: 100%;
		}
		.right {
			display: none;
		}
	}
	&::before {
		content: '';
		background-image: url(/img/General_blue_bar.svg);
		background-size: 100%;
		background-position: center left;
		background-repeat: no-repeat;
		position: absolute;
		left: -5px;
		top: -50px;
		width: 280px;
		height: 100px;
		@media all and (min-width: 1600px) {
			width: 420px;
			height: 150px;
			top: -75px;
		}
	}
	.text-container {
		margin: 0 auto;
		width: 60%;
		display: flex;
	}
	.left {
		width: 70%;
		h2 {
			color: ${(props) => props.theme.color.red};
			font-size: 2rem;
			line-height: 2.2rem;
			margin-bottom: 1rem;
		}
		p {
			color: #fff;
			margin-top: 5px;
			font-size: 1.3rem;
		}
	}
	.right {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		a {
			font-size: 1.5rem;
			padding: 0.75rem 2rem;
		}
	}
	@media all and (min-width: 1600px) {
		.left {
			h2 {
				font-size: 2.5rem;
				line-height: 3rem;
			}
			p {
				font-size: 1.5rem;
				line-height: 1.8rem;
			}
		}
	}
	@media all and (max-width: 1000px) {
		.text-container {
			width: 80%;
		}
	}
	@media all and (max-width: 868px) {
		padding: 5rem 3rem;
		&::before {
			width: 250px;
		}
		.text-container {
			flex-direction: column;
		}
		.left {
			width: 100%;
		}
		.right {
			width: 100%;
		}
	}
	@media all and (max-width: 868px) {
		flex-direction: column;
		padding: 5rem 3rem 4rem;
		&::before {
			display: none;
		}
		.left {
			width: 100%;
			text-align: center;
			p {
				margin: 1rem 0 2rem;
			}
		}
		.right {
			width: 100%;
			justify-content: center;
		}
	}
	@media all and (max-width: 668px) {
		padding: 5rem 0rem 4rem;
	}
	@media all and (max-width: 450px) {
		.text-container {
			width: 60%;
			min-width: 205px;
			.left {
				margin: 0 auto;
			}
		}
	}
`;

const data = {
	heading: 'READY TO GET OUT ON THE ICE?',
	copy: 'Learn more about our rink type and features.',
	rinksCopy:
		'Fill out our form and receive a free quote in approximately 24 hours.',
};

const RinksCTA = ({ className }) => {
	return (
		<StyledRinksCTA className={className}>
			<div className="text-container">
				<div className="left">
					<h2>{data.heading}</h2>
					<p>{className === 'rinks' ? data.rinksCopy : data.copy}</p>
				</div>
				<div className="right">
					<Link className="redButton" to="/our-rinks">
						OUR RINKS
					</Link>
				</div>
			</div>
		</StyledRinksCTA>
	);
};

export default RinksCTA;

import styled from 'styled-components';

export default styled.div`
	.navbar {
		padding: 0.5rem 3.5rem;
		@media all and (max-width: 768px) {
			padding: 0.5rem;
		}
		&.scrolled {
			opacity: 0;
		}
		.mobile-overlay {
			height: 100%;
			position: relative;
			top: 0;
			left: 0;
			transition: 0.5s ease;
			opacity: 0;
			background-color: rgba(0, 0, 0, 0.5);
		}
		&.mobileOpen .mobile-overlay {
			opacity: 1;
			position: fixed;
			transition: 0.5s ease;
			width: 100%;
		}
		&.mobileOpen .navbar-brand a {
			z-index: -1;
		}
		&.mobileOpen .navbar-burger {
			position: fixed;
		}
		&.is-fixed-top {
			opacity: 0;
			transition: 0.5s;
			display: flex;
			height: 3rem;
			min-height: 3rem;
			z-index: 29;
			&.scrolled {
				opacity: 1;
				transition: 0.5s;
			}
			.navbar-brand {
				min-height: auto;
			}
			.logo {
				width: 200px;
			}
			.redButton {
				padding: 0.25rem 1rem;
				height: 3rem;
				top: -0.5rem;
				@media all and (min-width: 1600px) {
					top: 0;
				}
			}
		}
		.hiddenButtonHoverImage {
			opacity: 0 !important;
			position: absolute;
		}
		.navbar-brand a {
			padding: 0;
			position: relative;
		}
		.navbar-item {
			background-color: #fff !important;
		}
		.logo {
			width: 150px;
			max-height: none;
		}
		.mobile-logo {
			display: none;
		}
		a {
			color: ${(p) => p.theme.color.headingRed};
			font-family: Fjalla One, sans-serif;
			text-transform: uppercase;
			padding: 0 1rem;
			&:hover {
				color: ${(p) => p.theme.color.blue} !important;
			}
			&.redButton {
				color: #fff !important;
				padding: 0.2rem 0.75rem;
				margin: auto 0 auto 1rem;
			}
		}
		.mobile-contact {
			display: none;
		}
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			a.navbar-item.redButton {
				display: inline-table;
				margin-top: 1.5rem;
			}
		}
		@media all and (min-width: 1600px) {
			padding: 1rem 5rem;
			.logo {
				width: 200px;
			}
			&.is-fixed-top {
				padding: 0 5rem;
				min-height: 4rem;
				height: 4rem;
				.logo {
					width: 250px;
				}
				.redButton {
					height: 4rem;
				}
			}
			a {
				font-size: 1.3rem;
				line-height: 1.5rem;
				padding: 0 2rem;
				&.redButton {
					padding: 0.5rem 1rem;
				}
			}
		}
		@media all and (max-width: 668px) {
			&.scrolled {
				opacity: 1;
			}
			&.is-fixed-top {
				display: none;
			}
			.mobile-logo {
				display: block;
				width: 150px;
				max-height: none;
				position: relative;
				margin: 10px 0 40px;
			}
			.logo {
				width: 94px;
			}
			.navbar-burger {
				color: ${(p) => p.theme.color.blue};
				position: absolute;
				right: 30px;
				top: 10px;
				z-index: 999;
				transform: translateY(-5px);
				transition: transform 86ms ease-out;
				background: none;
				p {
					font-family: Fjalla One, sans-serif;
					position: absolute;
					right: 64px;
					top: 14px;
					font-size: 18px;
					display: block;
					transition: 86ms ease-out;
				}
				span {
					height: 4px;
					width: 32px;
					left: calc(50% - 16px);
					&:nth-child(1) {
						top: calc(50% - 4px);
					}
					&:nth-child(2) {
						opacity: 0;
					}
					&:nth-child(3) {
						top: calc(50% - 9px);
					}
				}
				&.is-active {
					transform: none;
					p {
						opacity: 0;
						transform: translateY(-5px);
					}
					span {
						&:nth-child(1) {
							top: calc(50% - 4px);
							transform: rotate(45deg);
						}
						&:nth-child(3) {
							top: calc(50% - 4px);
							transform: rotate(-45deg);
						}
					}
				}
				&:not(.is-active) span:nth-child(1) {
					transform: translateY(5px);
				}
			}
			.navbar-menu {
				display: block;
				padding: 0 4rem 2rem 0.5rem;
				position: fixed;
				right: -330px;
				top: 0;
				opacity: 0;
				transition: right 0.5s cubic-bezier(0.08, 1, 0.36, 1),
					opacity 0s ease-in 0.5s;
				&.is-active {
					transition: right 0.5s cubic-bezier(0.08, 1, 0.36, 1), opacity 0s;
					opacity: 1;
					right: 0px;
				}
				.navbar-item {
					font-size: 2rem;
					padding: 0.3rem 1rem;
					&.redButton {
						color: #fff;
						width: 220px;
						text-align: center;
						margin-top: 1rem;
					}
				}
				.mobile-contact {
					display: block;
					margin-top: 30px;
					margin-left: 1rem;
					a {
						color: ${(p) => p.theme.color.darkBlue};
						display: block;
						font-size: 1.25rem;
						padding: 0.5rem 0;
						font-family: Roboto Condensed, sans-serif;
						text-transform: none;
					}
					.social {
						margin-top: 0.5rem;
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}
		@media all and (max-width: 350px) {
			.navbar-menu {
				padding: 0 4rem 1rem 0.5rem;
				&.is-active {
					right: -30px;
				}
				.mobile-logo {
					margin: 1rem 0 1.2rem;
					width: 120px;
				}
				.navbar-end {
					a {
						font-size: 1.7rem !important;
					}
				}
			}
		}
	}
`;

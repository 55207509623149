import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Link } from 'gatsby';

import NavStyle from '../styles/navStyle.js';

const NavMenu = () => {
	const [mobileMenu, setMobileMenu] = useState(false);
	const [scroll, setScroll] = useState(0);
	const [headerHeight, setHeaderHeight] = useState(100);

	useEffect(() => {
		const handleResize = () => {
			window.innerWidth < 1600 ? setHeaderHeight(100) : setHeaderHeight(144);
		};
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	// Event handler utilizing useCallback ...
	// ... so that reference never changes.
	const handler = useCallback(() => {
		setScroll(window.scrollY);
	}, [setScroll]);

	scrollListener(handler);

	function scrollListener(handler) {
		// Create a ref that stores handler
		const savedHandler = useRef();

		// Update ref.current value if handler changes.
		// This allows our effect below to always get latest handler ...
		// ... without us needing to pass it in effect deps array ...
		// ... and potentially cause effect to re-run every render.
		useEffect(() => {
			savedHandler.current = handler;
		}, [handler]);

		useEffect(
			() => {
				// Create event listener that calls handler function stored in ref
				const eventListener = (event) => savedHandler.current(event);

				// Add event listener
				global.addEventListener('scroll', eventListener);

				// Remove event listener on cleanup
				return () => {
					global.removeEventListener('scroll', eventListener);
				};
			},
			['scroll', global] // Re-run if eventName or element changes
		);
	}

	const navClass = mobileMenu ? 'navbar mobileOpen' : 'navbar';

	return (
		<NavStyle>
			<nav
				className={scroll > headerHeight ? navClass + ' scrolled' : navClass}
			>
				<img
					className="hiddenButtonHoverImage"
					src="/img/FLR_Blue_Button.png"
					alt="hover button"
				/>
				<div
					className="mobile-overlay"
					onClick={() => {
						if (!mobileMenu) setMobileMenu(true);
						else setMobileMenu(false);
					}}
				/>
				<div className="navbar-brand" onClick={(e) => e.stopPropagation()}>
					<Link className="navbar-item" to="/">
						<img
							className="logo"
							src="/img/FLR_header_logo.svg"
							alt="First Line Rinks Logo"
						/>
					</Link>
					<div
						role="button"
						className={mobileMenu ? 'navbar-burger is-active' : 'navbar-burger'}
						onClick={() => {
							if (!mobileMenu) setMobileMenu(true);
							else setMobileMenu(false);
						}}
					>
						<span />
						<span />
						<span />
						<p>MENU</p>
					</div>
				</div>
				<div className={mobileMenu ? 'navbar-menu is-active' : 'navbar-menu'}>
					<div className="navbar-end">
						<Link className="navbar-item" to="/">
							<img
								className="mobile-logo"
								src="/img/FLR_header_logo.svg"
								alt="First Line Rinks Logo"
							/>
						</Link>
						<Link className="navbar-item" to="/about">
							About Us
						</Link>
						<Link className="navbar-item" to="/our-rinks">
							Our Rinks
						</Link>
						<Link className="navbar-item" to="/projects">
							Projects Gallery
						</Link>
						<Link className="navbar-item redButton" to="/contact">
							Contact Us
						</Link>
					</div>
					<div className="mobile-contact">
						<a href="tel:7057166510">(705) 716-6510</a>
						<a href="mailto:info@firstlinerinks.com">info@firstlinerinks.com</a>
						<div className="social">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.facebook.com/firstlinerinks/"
							>
								<img src="/img/IconFacebook-blue.svg" alt="facebook icon" />
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://twitter.com/firstlinerinks/"
							>
								<img src="/img/IconTwitter-blue.svg" alt="twitter icon" />
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.instagram.com/firstlinerinks/"
							>
								<img src="/img/IconInsta-blue.svg" alt="instagram icon" />
							</a>
						</div>
					</div>
				</div>
			</nav>

			<nav
				className={
					scroll > headerHeight
						? 'navbar is-fixed-top scrolled'
						: 'navbar is-fixed-top'
				}
			>
				<div className="navbar-brand">
					<Link className="navbar-item" to="/">
						<img
							className="logo"
							src="/img/FLR_StickyNav_logo.svg"
							alt="First Line Rinks Logo"
						/>
					</Link>
				</div>
				<div className="navbar-menu">
					<div className="navbar-end">
						<Link className="navbar-item" to="/about">
							About Us
						</Link>
						<Link className="navbar-item" to="/our-rinks">
							Our Rinks
						</Link>
						<Link className="navbar-item" to="/projects">
							Projects Gallery
						</Link>
						<Link className="navbar-item redButton" to="/contact">
							Contact Us
						</Link>
					</div>
				</div>
			</nav>
		</NavStyle>
	);
};

export default NavMenu;

import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledFooter = styled.footer`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
	height: 3rem;
	background-color: ${(props) => props.theme.color.darkBlue};
	.logo {
		width: 70px;
	}
	.right {
		display: flex;
		align-items: center;
	}
	.contact a {
		color: #fff;
		padding-right: 1rem;
		transition: 0.5s;
		&:first-child {
			border-right: 1px solid white;
			margin-right: 1rem;
		}
	}
	.social {
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			display: flex;
		}
		padding: 0.5rem 0 0.5rem 1rem;
		a {
			transition: 0.5s;
			&:hover {
				opacity: 0.7;
			}
			img {
				height: 2rem;
				padding-left: 5px;
			}
		}
	}
	@media all and (min-width: 1600px) {
		padding: 0rem 4rem;
		height: 6rem;
		.logo {
			width: 120px;
		}
		a {
			font-size: 1.3rem;
			line-height: 1.5rem;
		}
		.social a img {
			height: 2.8rem;
			padding-left: 10px;
		}
	}
	@media all and (max-width: 668px) {
		height: auto;
		flex-direction: column;
		justify-content: center;
		padding: 3rem 0;
		.logo {
			width: 130px;
			a {
				opacity: 1 !important;
			}
		}
		.right {
			flex-direction: column;
		}
		.contact {
			display: flex;
			flex-direction: column;
			text-align: center;
			padding: 2rem 0;
			font-size: 1.5rem;
			line-height: 2.5rem;
			a {
				border-right: none !important;
				padding: 0;
				margin: 0 !important;
			}
		}
		.social {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 0;
			a img {
				height: 3rem;
				padding-left: 0;
			}
		}
	}
`;

const Footer = () => {
	return (
		<StyledFooter>
			<div className="logo">
				<Link to="/">
					<img src="/img/FLR_footer_logo.svg" alt="first line rinks logo" />
				</Link>
			</div>
			<div className="right">
				<div className="contact">
					<a href="tel:7057166510">(705) 716-6510</a>
					<a href="mailto:info@firstlinerinks.com">info@firstlinerinks.com</a>
				</div>
				<div className="social">
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.facebook.com/firstlinerinks/"
					>
						<img src="/img/IconFacebook-white.svg" alt="facebook icon" />
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://twitter.com/firstlinerinks/"
					>
						<img src="/img/IconTwitter-white.svg" alt="twitter icon" />
					</a>
					<a
						target="_blank"
						rel="noopener noreferrer"
						href="https://www.instagram.com/firstlinerinks/"
					>
						<img src="/img/IconInsta-white.svg" alt="instagram icon" />
					</a>
				</div>
			</div>
		</StyledFooter>
	);
};

export default Footer;

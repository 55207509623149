import React from 'react';
import styled from 'styled-components';

const StyledTestimonial = styled.div`
	display: flex;
	padding: 3rem;
	max-width: 1000px;
	margin: 0 auto;
	.left {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: center;
		.img-container {
			width: 100px;
		}
	}
	.right {
		width: 70%;
		display: flex;
		justify-content: center;
		flex-direction: column;
		padding-right: 3rem;
	}
	.author {
		font-weight: 700;
	}
	.noImage {
		width: 60%;
		margin: 0 auto;
	}
	@media all and (min-width: 1600px) {
		max-width: 1300px;
		p {
			font-size: 1.3rem;
			line-height: 1.7rem;
			&.author {
				margin-top: 0.5rem;
			}
		}
	}
	@media all and (max-width: 768px) {
		padding: 4rem 0;
		.noImage {
			width: 100%;
			margin: 0 6rem;
		}
		.left {
			margin-left: 4rem;
		}
		.right {
			margin-right: 3rem;
		}
	}
	@media all and (max-width: 600px) {
		display: flex;
		flex-direction: column;
		padding: 3rem 4rem;
		.left {
			width: 100%;
			margin: 0 0 2rem;
		}
		.right {
			width: 100%;
			padding: 0;
			margin: 0;
		}
		.noImage {
			margin: 0;
		}
	}
	@media all and (max-width: 450px) {
		padding: 3rem 2rem;
	}
`;

const Testimonial = ({ quote, author, img }) => {
	return (
		<>
			{quote !== '' && (
				<StyledTestimonial>
					{img !== '' && (
						<div className="left">
							<div className="img-container">
								<img src={img} alt="testimonial" />
							</div>
						</div>
					)}
					<div className={img !== '' ? 'right' : 'noImage'}>
						<p>"{quote}"</p>
						<p className="author">- {author}</p>
					</div>
				</StyledTestimonial>
			)}
		</>
	);
};

export default Testimonial;

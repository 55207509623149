import { createGlobalStyle } from 'styled-components';

import bulma from './bulmaSetup.scss';
import reset from './reset.css';

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Fjalla+One');
	@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,400i,700');

	${reset}
	${bulma}

	* {
		box-sizing: border-box;
	}

	html {
		font-size: 16px;
	}

	body {
		font-size: 16px;
		color: ${(p) => p.theme.color.grey};
		background-color: #fff;
		font-family: ${(props) => props.theme.fonts.body} !important;
		overflow: hidden;
		h1{
			font-family: ${(props) => props.theme.fonts.heading};
			font-size: 2.5rem;
			line-height: 2.8rem;
			color: #fff;
		} h2{
			font-family: ${(props) => props.theme.fonts.heading};
			font-size: 1.5rem;
			line-height: 2rem;
		}
		a.redButton, button.redButton {
			color: #fff;
			background-image: url('/img/FLR_Red_Button.png');
			background-position: center;
			background-size: cover;
			padding: 0.25rem 1.25rem;
			font-family: ${(props) => props.theme.fonts.heading};
			&:hover {
				background-image: url('/img/FLR_Blue_Button.png');
			}
		}
		p {
			line-height: 1.5rem;
		}
	}
`;

export default GlobalStyle;

import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styles/global';
import theme from '../styles/theme';
import NavMenu from './nav';
import Footer from './footer';

const Layout = ({ children }) => (
	<ThemeProvider theme={theme}>
		<>
			<GlobalStyle />
			<NavMenu />
			{children}
			<Footer />
		</>
	</ThemeProvider>
);

export default Layout;

export default {
	color: {
		headingRed: '#D42026',
		red: '#E0282F',
		blue: '#0063B0',
		darkBlue: '#203352',
		grey: '#57595B',
	},
	fonts: {
		heading: 'Fjalla One, sans-serif',
		body: 'Roboto Condensed, sans-serif',
	},
};

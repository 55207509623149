import React from 'react';

import Masthead from '../components/masthead';
import Testimonial from '../components/projects/testimonial';
import ProjectContent from '../components/projects/project-content';
import ProjectSlider from '../components/project-slider.js';
import RinksCTA from '../components/rinks-cta';
import Layout from '../components/layout';
import SEO from '../components/seo';

const capitalize = (string) => {
	let splitString = string.split(/\s/);
	let final = '';
	let i;
	for (i in splitString) {
		let word = splitString[i];
		word = word.charAt(0).toUpperCase() + word.slice(1);
		final = final.concat(word, ' ');
	}
	return final;
};

export default ({ pageContext }) => {
	const {
		title,
		description,
		quote,
		author,
		quoteImg,
		features,
		images,
	} = pageContext;

	return (
		<Layout>
			<SEO title={capitalize(title)} />
			<Masthead
				title={title}
				subtext="PROJECT GALLERY"
				img="/img/FLR_Rinks_Mast.jpg"
				className="project"
			/>
			<Testimonial quote={quote} author={author} img={quoteImg} />
			<ProjectContent description={description} features={features}>
				<ProjectSlider images={images} />
			</ProjectContent>
			<RinksCTA />
		</Layout>
	);
};

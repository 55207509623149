import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const StyledProjectContent = styled.div`
	padding: 4rem 4rem 6rem;
	background-image: url(/img/FLR_Ice.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	.content-cols {
		display: flex;
		.left {
			width: 45%;
			padding-right: 2rem;
			display: flex;
			align-items: center;
			p {
				margin-top: 1rem;
			}
			hr {
				background-color: #878b8c;
			}
			h3 {
				font-weight: 700;
				font-size: 1rem;
				color: ${(p) => p.theme.color.red};
				font-family: ${(p) => p.theme.fonts.heading};
				margin-bottom: 0.5rem;
			}
			.features span {
				font-size: 0.9rem;
				font-weight: 400;
				text-transform: uppercase;
				line-height: 1.1rem;
				font-family: ${(p) => p.theme.fonts.heading};
			}
			hr.lastLine {
				margin-bottom: 2rem;
			}
		}
		.right {
			width: 55%;
			margin: auto;
			padding: 0 2rem;
		}
	}
	@media all and (min-width: 1200px) {
		padding: 4rem 8rem;
		h2 {
			margin-left: 40%;
		}
		.content-cols {
			.left {
				width: 35%;
			}
			.right {
				width: 60%;
			}
		}
	}
	@media all and (min-width: 1400px) {
		padding: 6rem 15rem;
		.content-cols {
			.left {
				width: 40%;
			}
			.right {
				width: 55%;
			}
		}
	}
	@media all and (min-width: 1600px) {
		/* padding: 6rem 20rem; */
		h2 {
			font-size: 2rem;
			line-height: 2.5rem;
			max-width: 600px;
			margin-left: auto;
			margin-right: 6rem;
		}
		h3 {
			font-size: 1.6rem !important;
			line-height: 2rem;
		}
		p,
		a {
			font-size: 1.3rem !important;
			line-height: 1.5rem;
		}
		.features span {
			font-size: 1.1rem !important;
			line-height: 1.5rem !important;
		}
		.content-cols {
			.left {
				.text-container {
					max-width: 500px;
					margin-left: auto;
				}
			}
			.right {
				max-width: 600px;
				padding: 0 0 0 4rem;
				margin-right: 6rem;
			}
		}
	}
	@media all and (max-width: 900px) {
		padding: 3rem 3rem 5rem;
		.content-cols .left p {
			margin-top: 0;
		}
	}
	@media all and (max-width: 768px) {
		padding: 4rem 6rem;
		.content-cols {
			flex-direction: column;
			.left {
				width: 100%;
				padding-right: 0;
			}
			.right {
				width: 100%;
				margin-top: 4rem;
				padding: 0;
			}
		}
	}
	@media all and (max-width: 600px) {
		padding: 4rem;
	}
	@media all and (max-width: 450px) {
		padding: 4rem 2rem;
	}
`;

const ProjectContent = ({ description, features, children }) => (
	<StyledProjectContent>
		<div className="content-cols">
			<div className="left">
				<div className="text-container">
					<p>{description}</p>
					<hr />
					<h3>FEATURES:</h3>
					<div className="features">
						{features.map((item, index) => (
							<span key={index}>{index === 0 ? item : ` | ${item}`}</span>
						))}
					</div>
					<hr className="lastLine" />
					<Link className="redButton" to="/projects">
						ALL PROJECTS
					</Link>
				</div>
			</div>
			<div className="right">{children}</div>
		</div>
	</StyledProjectContent>
);

export default ProjectContent;
